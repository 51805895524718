
import React, { useEffect, useState } from 'react';
import axios from "../apiConfig"; // Import the centralized Axios instance
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const Vertical = () => {
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
  const fetchBlog = async () => {
    try {
      const response = await axios.get('/virticals');
      setItems(response.data); // Assuming your API returns happenings directly
    } catch (error) {
      setError(error);
      console.error('Error fetching blog data:', error);
    }
  };

  fetchBlog();
}, []);

  return (
    <>
    <div className="container-fluid mb-5 blog">
      <div className="row ">
        <div className="">
          <h2 className="my-5 text-center p-5 text-white display-3 fw-bold">
          Vertical
          </h2>
        </div>
      </div>
      </div>
 <div className="container">
      <div className="row">
        {items.map(item => (
          <div key={item.id} className="col-md-4 mb-4">
            <div className="card h-100 rounded-top-4 border shadow">
              <div className="card-image">
                <img src={item.image} alt={item.name || 'Image'} className="img-fluid" />
              </div>
              <div className="card-body">
                <h5 className="text-start">{item.name}</h5>
                <p className="text-start" dangerouslySetInnerHTML={{ __html: `${item.description.slice(0, 100)}...` }} />
                <Link to={`/details/${item.id}`} className="btn getstarted mt-2">Read More</Link>
              </div>
            </div>
          </div>
        ))}
      </div>

      {error && <div className="error">Error fetching data: {error.message}</div>}
      </div>
      </>
  );
};

export default Vertical;
