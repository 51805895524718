import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is included
import 'aos/dist/aos.css'; // AOS CSS
import 'glightbox/dist/css/glightbox.min.css'; // GLightbox CSS
import AOS from 'aos';
import GLightbox from 'glightbox';
import Counter from './Counter'; // Import the Counter component
import '../assets/css/index.css';

const Banner = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
    const lightbox = GLightbox({
      selector: '.glightbox',
    });

    // Cleanup GLightbox instance on component unmount
    return () => {
      if (lightbox) {
        lightbox.destroy();
      }
    };
  }, []);

  return (
    <>
      <section id="hero" className="hero">
        {/* Your hero image section */}
        <div className="container-fluid position-relative">
          <div className="row gy-5 align-items-center justify-content-center text-center" data-aos="fade-in">
            <div className="col-lg-12">
              <h1 className="text-white fw-bold display-3">U.P. Industrial Consultants Limited</h1>
              <p className="text-white">(Established by Government of UP, SIDBI, IFCI & Nationalized Bank in 1974)</p>
              <h3 className="text-white">ADVANCING ON THE PATH OF PROGRESS</h3>
              <div className="d-flex justify-content-center my-5">
                <a href="/assets/images/NRC-UPICL-2023-24-Management.pdf"  target="_blank" className="btn-get-started text-white">CAG REPORT</a>
                <button type="button" className="btn-get-started text-white btn-lg ms-2" data-bs-toggle="modal" data-bs-target="#pledgeModal">
                  PLEDGE
                </button>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <Counter />
          </div>
        </div>
      </section>

      {/* PLEDGE Modal */}
      <div className="modal fade" id="pledgeModal" tabIndex="-1" aria-labelledby="pledgeModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="pledgeModalLabel">PLEDGE Scheme</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <h2 className="text-center mb-4">PLEDGE</h2>
              <p classname="text-start">Recognizing that availability of industrial plots is the biggest bottleneck in rapid industrialization, UP Cabinet on 28.1.23 passed a new scheme called PLEDGE – to promote the establishment of private MSME parks to give a boost to industrialization of the state.</p>
              <ul className="list-group list-group-flush text-start mb-4">
                <li className="list-group-item"><strong>P</strong> - Promoting</li>
                <li className="list-group-item"><strong>L</strong> - Leadership and</li>
                <li className="list-group-item"><strong>E</strong> - Enterprise for</li>
                <li className="list-group-item"><strong>D</strong> - Development of</li>
                <li className="list-group-item"><strong>G</strong> - Growth</li>
                <li className="list-group-item"><strong>E</strong> - Engines</li>
              </ul>
              <h3 className="text-center mb-4">Salient Points</h3>
              <ol className="list-group list-group-numbered text-start">
                <li className="list-group-item">It provides easy finance to the promoter for setting up private industrial park.</li>
                <li className="list-group-item">Cost of internal/external development of land is pegged at Rs 50 lakhs/acre.</li>
                <li className="list-group-item">90% of the value of land at Collector rate or the estimated cost of development of industrial park, whichever is less, will be provided by state government to the promoter as loan. Balance amount for development, if any, to be raised by the promoter from Banks/Financial Institutions.</li>
                <li className="list-group-item">Promoter will have to pledge the land in favour of the state government.</li>
                <li className="list-group-item">The rate of interest will be 1% simple interest for 3 years and 6% (simple interest) for next 3 years after which it becomes 7% compound interest.</li>
                <li className="list-group-item">The loan amount will be provided in two equal installments.</li>
                <li className="list-group-item">The scheme covers private MSME parks developed on an area of 10 to 50 acres. 75% of developed area to be allotted to MSME units.</li>
                <li className="list-group-item">Sale proceeds of industrial plots to be deposited in an escrow account from which every stakeholder gets its share accordingly.</li>
                <li className="list-group-item">There is no penalty for foreclosure. The earlier the better.</li>
                <li className="list-group-item">State Government is setting up a corpus/revolving fund of Rs 2500 crores through budgetary provision in the next five years. Rs 300 crores already provided in 2022-23.</li>
              </ol>
              <div className="d-flex justify-content-center mt-4">
                <a href="/assets/images/PLEDGE.pdf"  target="_blank" className="btn btn-primary me-2">Govt. Order</a>
                <a href="/assets/images/PLEDGE-guidelines.pdf"  target="_blank" className="btn btn-primary">PLEDGE Guideline</a>
              </div>
            </div>
          
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
