import React, { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const NumberCounter = ({ count }) => {
  const [currentCount, setCurrentCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentCount((prevCount) => {
        const newCount = prevCount + 1;
        if (newCount >= count) {
          clearInterval(interval);
        }
        return newCount;
      });
    }, 10); // Adjust the interval to control the speed of the animation

    return () => clearInterval(interval);
  }, [count]);

  const getColor = (count) => {
    if (count < 50) return '#DF7C21';
    if (count < 100) return '#DF7C21';
    return '#DF7C21';
  };

  return (
    <div className="counter-circle mx-auto">
      <CircularProgressbar
        value={currentCount}
        maxValue={count}
        text={`${currentCount}+`}
        styles={buildStyles({
          textSize: '16px',
          pathColor: getColor(currentCount),
          textColor: getColor(currentCount),
          trailColor: '#d6d6d6',
        })}
      />
    </div>
  );
};

const Counter = () => {
  return (
    <div className="container-fluid mb-5">
      <div className="row justify-content-center">
        <div className="col-lg-3 col-md-3 col-sm-6">
          <div className="text-center">
            <NumberCounter count={50} />
            <h3 className='h6 mt-2 text-white'>YEAR LEGACY</h3>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-6">
          <div className="text-center">
            <NumberCounter count={2000} />
            <h3 className='h6 mt-2 text-white'>ASSIGNMENTS</h3>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-6">
          <div className="text-center">
            <NumberCounter count={50} />
            <h3 className='h6 mt-2 text-white'>EXPERTS</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Counter;
