import React from 'react';
import BannerSection from './BannerSection';
import Ourclient from './Ourclient';
// import './custom.css'; // Import the custom CSS file
import Oursection from './Oursection';
import '../assets/css/index.css';

import VirticalSection from './VirticalSection';
import HappeningSection from './HappeningSection';
import NewsSection from './NewsSection';
import Intatives from './Intatives';
import Covrage from './Covrage';



function Home() {
  return (
      <>
        <BannerSection />
        <Ourclient />
        <Covrage />
        <Oursection />
        <VirticalSection />
        <HappeningSection />
        <Intatives />
        <NewsSection /> 
       </>

  );
}

export default Home;
