import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import { User, Mail, Phone } from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faInstagram, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faBriefcase, faMapMarkerAlt, faCalendarAlt, faUsers } from '@fortawesome/free-solid-svg-icons';

import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
// import './custom.css';

axios.defaults.baseURL = 'https://new.upicon.in/api';
// axios.defaults.baseURL ='http://127.0.0.1:8000/api';

const Careers = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    experience: '', // Added experience field
    message: ''
  });

  const [jobVacancies, setJobVacancies] = useState([]);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false); // State to handle modal visibility
  const [selectedJob, setSelectedJob] = useState(null); // State to store selected job details
  const [resumeFile, setResumeFile] = useState(null); // State to handle resume file upload

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value
    });
  };

  const handleFileChange = (e) => {
    setResumeFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/submit-contact-form', formData);
      alert('Contact form submitted successfully');
      // Reset the form data after successful submission
      setFormData({
        name: '',
        email: '',
        phone: '',
        experience: '',
        message: ''
      });
    } catch (error) {
      console.error('Error submitting contact form:', error);
      alert('Failed to submit contact form');
    }
  };
  

  const handleShowModal = (job) => {
    setSelectedJob(job);
    setShowModal(true);
  };

  const handleModalSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataWithResume = new FormData();
      Object.keys(formData).forEach(key => {
        formDataWithResume.append(key, formData[key]);
      });
      formDataWithResume.append('resume', resumeFile);

      const response = await axios.post('/submit-form', formDataWithResume, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      alert('Application submitted successfully');
      // Reset the form data after successful submission
      setFormData({
        name: '',
        email: '',
        phone: '',
        experience: '',
        message: ''
      });
      setResumeFile(null); // Reset resume file state
      setShowModal(false); // Close modal after submission
    } catch (error) {
      console.error('Error submitting application form:', error);
      alert('Failed to submit application form');
    }
  };

  useEffect(() => {
    const fetchJobVacancies = async () => {
      try {
        const response = await axios.get('/job-vacancies');
        setJobVacancies(response.data);
      } catch (error) {
        setError(error);
        console.error('Error fetching job vacancies data:', error);
      }
    };

    fetchJobVacancies();
  }, []);

  return (
    <section id="career" className="mt-5">
      <div className="container-fluid position-relative career">
        <div className="container mt-5">
          <div className="row gy-5 align-items-center justify-content-center text-center">
            <div className="">
              <h1 className="my-5 text-center p-5 text-white display-5 fw-bold">Unlock Your Potential Join Our Team of Visionaries</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container my-5">
  <h2 className="text-center mb-4">Current Job Vacancies</h2>
  <div className="row gy-4">
    {error && <div className="error">Error fetching job vacancies data: {error.message}</div>}
    {jobVacancies.map((job) => (
      <div key={job.id} className="col-md-4">
        <div className="border shadow card cardcarrer">
          <div className="card-body">
            <h5 className="card-title text-black sectionhead">
               <strong>{job.title}</strong>
            </h5>
          
                <p className="card-text text-black text-start">
                  <FontAwesomeIcon icon={faBriefcase} /> <strong className='ms-2'>Experience:</strong> {job.experience}
                </p>
        
                <p className="card-text text-black text-start">
                  <FontAwesomeIcon icon={faMapMarkerAlt} /> <strong className='ms-2'>Location:</strong> {job.location}
                </p>
                <p className="card-text text-black text-start">
                <FontAwesomeIcon icon={faUsers} /> <strong className='ms-2'>No. of Vacancy:</strong> {job.openings}
                </p>
            
                <p className="card-text text-black text-start">
                <FontAwesomeIcon icon={faCalendarAlt} /> <strong className='ms-2'>Closing Date:</strong> {job.closing_date}
                </p>
            
            <button className="btn btn-primary mt-3" onClick={() => handleShowModal(job)}>Apply</button>
          </div>
        </div>
      </div>
    ))}
  </div>
</div>



      <div className="container my-5">
        <div className="row gy-5 align-items-center justify-content-center text-start">
          <div className="col-lg-6 contact-background">
            <div className="contact-content">
              <h5 className='text-start shorthead sectionhead'>Contact Us</h5>
              <h1 className='text-start text-black mt-4'>Unlock Your Potential : Join Our Team of Visionaries</h1>
              <p className='text-start text-black mt-4'>Explore Exciting Career Opportunities and Make a Meaningful Impact</p>
              <div className="list-icon mt-4">
                <i className="bi bi-envelope me-2"></i><span className='fw-bold'>mdoffice@upicon.in</span>
              </div>
              <div className="list-icon mt-4">
                <i className="bi bi-telephone me-2"></i><span className='fw-bold'> 0522 – 423-3727</span>
              </div>
              <div className="list-icon my-4">
                <i className="bi bi-clock me-2"></i><span className='fw-bold'> Mon – Sat: 09.30 AM – 06.00 PM 2nd & 4th Sat: Closed</span>
              </div>
              <hr className='hrline mt-3' />
              <div className="col-md-6 text-center text-md-end mt-3">
                <div className="social-icons">
                  <a href="https://www.linkedin.com/company/upicon1974/" target="_blank" rel="noopener noreferrer" className="icon-round me-2">
                    <FontAwesomeIcon icon={faLinkedin} className="icon-size" color="#ffffff" />
                  </a>
                  <a href="https://www.instagram.com/upicon_1974/?igsh=bnNoYmF3eHA5Z284" target="_blank" rel="noopener noreferrer" className="icon-round me-2">
                    <FontAwesomeIcon icon={faInstagram} className="icon-size" color="#ffffff" />
                  </a>
                  <a href="https://www.facebook.com/people/Upicon/100091460436785/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer" className="icon-round me-2">
                    <FontAwesomeIcon icon={faFacebook} className="icon-size" color="#ffffff" />
                  </a>
                  <a href="https://twitter.com/InfoUpicon" target="_blank" rel="noopener noreferrer" className="icon-round me-2">
                    <FontAwesomeIcon icon={faTwitter} className="icon-size" color="#ffffff" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-wrapper">
              <div className="form-container">
                <form onSubmit={handleSubmit}>
                  <h1 className="mb-4">Send us a message</h1>
                  <p>Unlock Your Potential: Join Our Team of Visionaries</p>
                  <div className="form-group position-relative">
                    <label htmlFor="name" className="d-block">
                      <User className="icon" />
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="form-control form-control-lg thick"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group position-relative">
                    <label htmlFor="email" className="d-block">
                      <Mail className="icon" />
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="form-control form-control-lg thick"
                      placeholder="E-mail"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group position-relative">
                    <label htmlFor="phone" className="d-block">
                      <Phone className="icon" />
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      className="form-control form-control-lg thick"
                      placeholder="Phone Number"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                  </div>
                 
                  <div className="form-group position-relative">
                   
                    <textarea
                      id="message"
                      className="form-control form-control-lg thick"
                      rows="4"
                      placeholder="Message"
                      value={formData.message}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <button type="submit" className="btn btn-primary btn-lg mt-3">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Application Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Apply for {selectedJob?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {selectedJob && (
    <div className="row">
      <div className="col-md-6">
        <p><strong>Location:</strong> {selectedJob.location}</p>
      </div>
      <div className="col-md-6">
        <p><strong>Experience:</strong> {selectedJob.experience}</p>
      </div>
      <div className="col-md-6">
        <p><strong>Openings:</strong> {selectedJob.openings}</p>
      </div>
      {/* Additional fields if needed */}
    </div>
  )}
          <form onSubmit={handleModalSubmit}>
          <div className="row">
          <div className="col-12">
              <label htmlFor="name" className="form-label">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div></div>
            <div className="row">
            <div className="col-12">
              <label htmlFor="email" className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div></div>
            <div className="row">
            <div className="col-6">
        
              <label htmlFor="phone" className="form-label">Phone</label>
              <input
                type="text"
                className="form-control"
                id="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-6">
              <label htmlFor="experience" className="form-label">Experience</label>
              <input
                type="text"
                className="form-control"
                id="experience"
                value={formData.experience}
                onChange={handleChange}
                required
              />
            </div></div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">Message</label>
              <textarea
                className="form-control"
                id="message"
                rows="3"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="mb-3">
              <label htmlFor="resume" className="form-label">Resume</label>
              <input
                type="file"
                className="form-control"
                id="resume"
                onChange={handleFileChange}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary">Submit Application</button>
          </form>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default Careers;
