import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faInstagram, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';
import '../assets/css/SocialIcons.css';
import VisitorCount from './VisitorCount';

const Footer = () => {
  const [showIcons, setShowIcons] = useState(false);

  const toggleIcons = (e) => {
    e.preventDefault(); // Prevent default behavior of anchor tag
    setShowIcons(!showIcons); // Toggle the state of showIcons
  };

  return (
    <>
      <footer className="footer-area text-black">
        <div className="container pt-4">
          <div className="row justify-content-center mt-5">
            <div className="col-lg-2 col-md-12 col-sm-12 text-center mb-4">
              <img
                src="/assets/images/upicon-cleberation.webp"
                alt="Logo"
                className="my-2 logo"
                height="50"
              />
              
            </div>
            <div className="col-lg-3 col-md-6 col-12 col-sm-6 text-start mb-4">
              <h5>Registered Office Address:</h5>
              <p>
                5th Floor, Kabir Bhawan, G. T. Road,<br/>
                Kanpur – 208002,<br/>
                Uttar Pradesh, India
              </p>
            </div>
            <div className="col-lg-3 col-md-6 col-12 col-sm-6 text-start mb-4">
              <h5>Corporate Office Address:</h5>
              <p>
                A-708-709, 7th Floor, Rohtas Summit Building,<br/>
                Vibhuti Khand, Gomti Nagar,<br/>
                Lucknow – 226 010,<br/>
                Uttar Pradesh
              </p>
            </div>
            <div className="col-lg-2 col-md-6 col-12 col-sm-6 text-start mb-4">
              <h5>Office Hours:</h5>
              <p>
                Mon–Sat: 09:30AM–6:00PM<br/>
                2nd & 4th Sat: Closed<br/>
                Sunday: Closed
              </p>
            </div>
            <div className="col-lg-2 col-md-6 col-12 col-sm-6 text-start mb-4">
              <h5>Contact Us:</h5>
              <p>
                Email: <a href="mailto:info@upicon.in" className='text-warning fw-bold'>info@upicon.in</a><br/>
                Phone: 0522 423 3727<br/>
                Other Enquiry: <a href="mailto:mdoffice@upicon.in" className='text-warning fw-bold'>mdoffice@upicon.in</a>
              </p>
            </div>
          </div>
        </div>
<hr/>
        <div className="footer-bottom">
          <div className="container">
            <div className="row w-100">
              <div className="col-md-12 text-center mb-3 mb-md-0">
                <div className="visitorCountDiv">
                  <div id="abct" className="notranslate">
                    <p id="numberofvisitor"> Copyright @1974 - 2024 UPICON. All rights reserved</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <VisitorCount />
      </footer>

      <div className="social-icons">
        <div className={`sbuttons ${showIcons ? 'active' : ''}`}>
          <a href="https://www.linkedin.com/company/upicon1974/" target="_blank" className="sbutton linkedin" tooltip="LinkedIn"><FontAwesomeIcon icon={faLinkedin} /></a>
          <a href="https://twitter.com/InfoUpicon" target="_blank" className="sbutton twitt" tooltip="Twitter"><FontAwesomeIcon icon={faTwitter} /></a>
          <a href="https://www.instagram.com/upicon_1974/?igsh=bnNoYmF3eHA5Z284" className="sbutton instagram" tooltip="Instagram"><FontAwesomeIcon icon={faInstagram} /></a>
          <a href="https://www.facebook.com/people/Upicon/100091460436785/?mibextid=LQQJ4d" target="_blank" className="sbutton fb" tooltip="Facebook"><FontAwesomeIcon icon={faFacebook} /></a>
          <a href="#" className="sbutton mainsbutton" onClick={toggleIcons} tooltip="Share"><FontAwesomeIcon icon={faShareAlt} /></a>
        </div>
        
      </div>

     

      <a href="#" className="back-to-top d-flex align-items-center justify-content-center">
        <i className="bi bi-arrow-up-short icon-size"></i>
      </a>
    
    </>
  );
};

export default Footer;
