import React, { useEffect, useState } from 'react';
import axios from "../apiConfig"; // Import the centralized Axios instance
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const Happenings = () => {
  const [happenings, setHappenings] = useState([]);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedHappening, setSelectedHappening] = useState(null);
  const [viewMore, setViewMore] = useState(false);

  useEffect(() => {
    const fetchHappenings = async () => {
      try {
        const response = await axios.get('/happenings');
        setHappenings(response.data); // Assuming your API returns happenings directly
      } catch (error) {
        setError(error);
        console.error('Error fetching happenings data:', error);
      }
    };

    fetchHappenings();
  }, []);

  const handleShowModal = (happening) => {
    setSelectedHappening(happening);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedHappening(null);
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <div className="container-fluid mb-5 blog">
        <div className="row ">
          <div className="">
            <h2 className="my-5 text-center p-5 text-white display-3 fw-bold">
              Happening
            </h2>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {happenings.map((happening) => (
            <div key={happening.id} className="col-md-4 mb-4">
              <div className="card h-100 rounded-top-4 border shadow">
                <div className="card-image">
                  <img
                    src={happening.image}
                    alt={happening.title || 'Image'}
                    className="img-fluid"
                  />
                </div>
                <div className="card-body">
                  <h5 className="text-start">{happening.title}</h5>
                  <p className="text-start">
                    {happening.description
                      ? <span dangerouslySetInnerHTML={{ __html: `${happening.description.slice(0, 80)}...` }} />
                      : "No description available."}
                  </p>
                  <Link to={`/details/${happening.id}`} className="btn getstarted">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>

        {error && <div className="error">Error fetching data: {error.message}</div>}
      </div>
    </>
  );
};

export default Happenings;
