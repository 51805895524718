import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../assets/css/ClientLogoCarousel.css';

const ClientLogoCarousel = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    };

    return (
        <div className="container client my-5">
            <h3 className="text-start fw-bold sectionhead">Our Clients</h3>
            <Slider {...settings}>
                <div className="carousel-item">
                    <img src="./assets/images/Artboard-1.webp" alt="Client Logo 1" />
                </div>
                <div className="carousel-item">
                    <img src="./assets/images/Artboard-2.webp" alt="Client Logo 2" />
                </div>
                <div className="carousel-item">
                    <img src="./assets/images/Artboard-3.webp" alt="Client Logo 3" />
                </div>
                <div className="carousel-item">
                    <img src="./assets/images/Artboard-4.webp" alt="Client Logo 4" />
                </div>
                <div className="carousel-item">
                    <img src="./assets/images/Artboard-5.webp" alt="Client Logo 5" />
                </div>
                <div className="carousel-item">
                    <img src="./assets/images/Artboard-6.webp" alt="Client Logo 6" />
                </div>
                <div className="carousel-item">
                    <img src="./assets/images/she_cast.jpg" alt="Client Logo 7" />
                </div>
                <div className="carousel-item">
                    <img src="./assets/images/turisam.png" alt="Client Logo 8" />
                </div>
            </Slider>
        </div>
    );
};

export default ClientLogoCarousel;
