import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './component/Header';
import Home from './component/Home';
import About from './component/About'; 
import ConsultancyGroup from './component/ConsultancyGroup';
import FinancialInclusion from './component/FinancialInclusion'; 
import Retail from './component/Retail';
import CSRTraining from './component/CSRTraining';
import Technology from './component/Technology';
import SkillDevelopment from './component/SkillDevelopment';
import Empowerment from './component/Empowerment';  
import Footer from './component/Footer'; 
import './App.css';
import SmartFund from './component/SmartFund';
import Careers from './component/Careers'; 
import BusinessUnits from './component/BusinessUnits';
import Contact from './component/Contact'; 
import DetailedView from './component/DetailedView';
import Blog from './component/Blog';
import Happencard from './component/Happencard';
import ScrollToTop from './component/ScrollToTop';
import Vertical from './component/Vertical';
function App() {
  return (
    <Router>
      <div className="App">
      <ScrollToTop />
        <Header />
       
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/smart-fund" element={<SmartFund />} />
            <Route path="/blog" element={<Blog />} /> 
            <Route path="/careers" element={<Careers />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/financial-Inclusion" element={<FinancialInclusion />} /> 
            <Route path="/consultancy-group" element={<ConsultancyGroup />} />
            <Route path="/csr-training" element={<CSRTraining />} /> 
            <Route path="/technology" element={<Technology />} /> 
            <Route path="/skill-development" element={<SkillDevelopment />} />
            <Route path="/retail" element={<Retail />} />
            <Route path="/Empowerment" element={<Empowerment />} /> 
            <Route path="/businesunits" element={<BusinessUnits />} /> 
            {/* <Route path="/details/:slug" element={<DetailedView />} />
            <Route path="/details/:id" element={<DetailedView />} /> */}
             <Route path="/blog-details/:slug" element={<DetailedView />} />

      

            <Route path="/happening" element={<Happencard />} />
            <Route path="/vertical" element={<Vertical />} />
            
            </Routes>
        </main>
        <Footer /> 
      </div>
    </Router>
  );
}

export default App;
