import React, { useEffect, useState } from "react";
import axios from "../apiConfig"; // Import the centralized Axios instance
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const CardSlider = ({ baseUrl }) => {
  const [readMore, setReadMore] = useState([]);
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchSliders = async () => {
      try {
        const response = await axios.get('/sliders');
        setItems(response.data);
        setReadMore(Array(response.data.length).fill(false));
      } catch (error) {
        setError(error);
        console.error('Error fetching sliders data:', error);
      }
    };

    fetchSliders();
  }, []);

  if (error) return <div>Error fetching data: {error.message}</div>;

  return (
    <section id="latest-news" className="latest-news mb-5">
      <div className="container">
        <div className="row align-items-center mb-4" data-aos="fade-up" data-aos-delay="100">
          <div className="col-lg-6">
            <h5 className="section-head shorthead sectionhead text-start">Latest News</h5>
            <h2 className="fw-bold mt-4 text-start">UPICON Latest News And Blogs</h2>
            <p className="text-start">
              UPICON has been instrumental in giving consultancy to big, medium, and small industries, building microentrepreneurial ecosystems, skills and training, banking inclusion, UNESCO projects, social welfare projects, women empowerment, safety and audit, etc.
            </p>
          </div>
          <div className="col-lg-6 col-md-12 starttedbtn">
            <div className="text-end mx-4">
              <Link to="/blog" className="btn getstarted">View All News</Link>
            </div>
          </div>
        </div>
        <div className="row">
          {items.slice(0, 3).map((item, index) => (
            <div key={item.id} className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <div className="card rounded-4 border-0 shadow-sm news-card">
                <img 
                  src={item.image} 
                  className="card-img-top rounded-4"
                  style={{ height: "320px", objectFit: "cover" }} 
                  alt={item.title || 'Image'} 
                />
                <div className="card-body">
                  <h5 className="card-title text-start">{item.title}</h5>
                  <p className="card-text text-start">
                    {readMore[index] ? item.text : `${item.text.slice(0,45 )}...`}
                  </p>
                  <Link 
                    to={`/blog-details/${item.slug}`} 
                    className="mt-2 text-start learn-more-btn d-block"
                  >
                    {readMore[index] ? 'Read less' : 'Read more'}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CardSlider;
