import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const location = useLocation();

  const toggleMenu = () => setIsOpen(prev => !prev);
  const closeMenu = () => {
    setIsOpen(false);
    setActiveDropdown(null);
  };
  const toggleDropdown = (index) => setActiveDropdown(prev => (prev === index ? null : index));
  const toggleButtonDropdown = () => setIsDropdownOpen(prev => !prev);

 
  return (
    <>
      <header id="header" className={`fixed-top d-flex shadow align-items-center ${isOpen ? 'navbar-mobile' : ''}`}>
        <div className="container d-flex align-items-center justify-content-between">
          <h1 className="logo">
            <img
              src="/assets/images/upicon.webp"
              alt="Logo"
              className="my-2 logo"
              height="80"
            />
          </h1>
          <nav id="navbar" className={`navbar ${isOpen ? 'navbar-mobile' : ''}`}>
            <ul>
              <li>
                <Link className={`nav-link scrollto ${location.pathname === '/' ? 'active' : ''}`} to="/" onClick={closeMenu}>Home</Link>
              </li>
              <li>
                <Link className={`nav-link scrollto ${location.pathname === '/about' ? 'active' : ''}`} to="/about" onClick={closeMenu}>About Us</Link>
              </li>
              <li>
                <Link className={`nav-link scrollto ${location.pathname === '/smart-fund' ? 'active' : ''}`} to="/smart-fund" onClick={closeMenu}>SmartCap Fund</Link>
              </li>
              <li>
                <Link className={`nav-link scrollto ${location.pathname === '/careers' ? 'active' : ''}`} to="/careers" onClick={closeMenu}>Careers</Link>
              </li>
              <li>
                <Link className={`nav-link scrollto ${location.pathname === '/blog' ? 'active' : ''}`} to="/blog" onClick={closeMenu}>Blog</Link>
              </li>
              <li className={`dropdown ${activeDropdown === 0 ? 'dropdown-active' : ''}`} onClick={() => toggleDropdown(0)}>
                <Link to="#" className={`${location.pathname.includes('/businesunits') ? 'active' : ''}`}>
                  <span>Business Units</span> <i className="bi bi-chevron-down"></i>
                </Link>
                <ul className={`${activeDropdown === 0 ? 'dropdown-active' : ''}`}>
                  <li><Link className={`${location.pathname === '/financial-Inclusion' ? 'active' : ''}`} to="/financial-Inclusion" onClick={closeMenu}>Banking & Financial Inclusion</Link></li>
                  <li><Link className={`${location.pathname === '/consultancy-group' ? 'active' : ''}`} to="/consultancy-group" onClick={closeMenu}>Consultancy Group</Link></li>
                  <li><Link className={`${location.pathname === '/csr-training' ? 'active' : ''}`} to="/csr-training" onClick={closeMenu}>CSR Training</Link></li>
                  <li><Link className={`${location.pathname === '/technology' ? 'active' : ''}`} to="/technology" onClick={closeMenu}>Technology & Innovation</Link></li>
                  <li><Link className={`${location.pathname === '/skill-development' ? 'active' : ''}`} to="/skill-development" onClick={closeMenu}>Training & Skill Development</Link></li>
                  <li><Link className={`${location.pathname === '/retail' ? 'active' : ''}`} to="/retail" onClick={closeMenu}>Retail</Link></li>
                  <li><Link className={`${location.pathname === '/empowerment' ? 'active' : ''}`} to="/empowerment" onClick={closeMenu}>Workforce Empowerment</Link></li>
                </ul>
              </li>
              <li>
                <Link className="nav-link getstarted scrollto" to="/contact" onClick={closeMenu}>Contact Us</Link>
              </li>
              
            </ul>
            <i className={`bi ${isOpen ? 'bi-x' : 'bi-list'} mobile-nav-toggle`} onClick={toggleMenu}></i>
          </nav>
        </div>
      </header>

    </>
  );
};

export default Header;
