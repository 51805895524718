import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "../apiConfig"; // Import the centralized Axios instance

const Intatives = () => {
  const [initiatives, setInitiatives] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch data from the API
  useEffect(() => {
    const fetchInitiatives = async () => {
      try {
        const response = await axios.get("/initiatives"); // Update with your API endpoint
        setInitiatives(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchInitiatives();

    // Re-fetch data on window resize (optional)
    window.addEventListener("resize", adjustCardHeights);
    return () => {
      window.removeEventListener("resize", adjustCardHeights);
    };
  }, []);
  useEffect(() => {
    if (initiatives.length > 0) {
        const owlItems = document.querySelectorAll('.owl-carousel');
        owlItems.forEach(owl => {
            owl.addEventListener('touchstart', handleTouchStart, { passive: true });
        });

        function handleTouchStart(e) {
            // Custom logic if needed
        }

        return () => {
            owlItems.forEach(owl => {
                owl.removeEventListener('touchstart', handleTouchStart);
            });
        };
    }
}, [initiatives]);
  // Function to adjust card heights based on the tallest card in the row
  const adjustCardHeights = () => {
    const carousel = document.querySelector(".owl-carousel");
    if (!carousel) return;

    const cards = carousel.querySelectorAll(".initiative-card");
    if (!cards || cards.length === 0) return;

    let maxCardHeight = 0;

    cards.forEach((card) => {
      const cardBody = card.querySelector(".card-body");
      if (cardBody) {
        cardBody.style.height = "160px"; // Set a fixed height (adjust as needed)
        const cardHeight = cardBody.offsetHeight;
        if (cardHeight > maxCardHeight) {
          maxCardHeight = cardHeight;
        }
      }
    });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error fetching initiatives: {error.message}</p>;

  return (
    <section id="initiatives" className="initiatives ">
      <div className="container">
        <div className="row gy-4" data-aos="fade-up" data-aos-delay="100">
          <div className="col-lg-6">
            <h5 className="text-start shorthead sectionhead">Featured Initiatives</h5>
            <h2 className="text-start fw-bold mt-4">UPICON Different Initiatives For Different Benefits</h2>
            <p className="text-start">
              UPICON has been instrumental in giving consultancy to big, medium, and small industries, building
              microentrepreneurial ecosystems, skills and training, banking inclusion, UNESCO projects, social welfare
              projects, women empowerment, safety and audit, etc.
            </p>
          </div>
        </div>
        <OwlCarousel
          className="owl-theme initiatives-carousel"
          loop
          margin={20} // Adjust the margin to reduce space between items
          autoplay={true}
          nav={false} // Hide navigation arrows
          dots={false} // Hide dots
          responsive={{
            0: {
              items: 1, // Display one item in mobile view (col-12)
            },
            576: {
              items: 2, // Display two items from 576px and above (col-md-6)
            },
            768: {
              items: 3, // Display three items from 768px and above (col-lg-4)
            },
            992: {
              items: 4, // Display four items from 992px and above (col-xl-3)
            },
            1200: {
              items: 3, // Display three items from 1200px and above (full width)
            },
            1500: {
              items: 4, // Display three items from 1200px and above (full width)
            },
          }}
        >
          {/* Carousel items */}
          {initiatives.map((initiative, index) => (
            <div key={index} className="card initiative-card mt-5">
              <img
                src={initiative.image}
                alt={initiative.title}
                style={{ height: "250px", objectFit: "cover" }}
                className="initiative-img"
              />
              <div className="card-body">
                <h6 className="card-title text-start">{initiative.title}</h6>
              </div>
            </div>
          ))}
        </OwlCarousel>
      </div>
    </section>
  );
};

export default Intatives;
