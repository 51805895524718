import React, { useEffect, useState } from 'react';
import axios from '../apiConfig'; // Import the centralized Axios instance
import { useParams } from 'react-router-dom';

const DetailedView = () => {
    const { slug } = useParams();
    const [item, setItem] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDetailed = async () => {
            try {
                const response = await axios.get(`/sliders/slug/${slug}`);
                setItem(response.data);
            } catch (error) {
                setError(error);
                console.error('Error fetching slider data:', error);
            }
        };

        if (slug) {
            fetchDetailed();
        }
    }, [slug]);

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (!item) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div className="container-fluid mb-5 blog">
                <div className="row">
                    <div className="">
                        <h2 className="my-5 text-center p-5 text-white display-3 fw-bold">Blogs</h2>
                    </div>
                </div>
            </div>
            <div className="container detailed-view-container">
                <div className="row mt-4">
                    <div className="col-lg-12 col-md-12">
                        <div className="detailed-view card shadow-sm border-0 rounded-4 p-4">
                            <img src={item.image} alt={item.title || 'Image'} className="img-fluid rounded-4 mb-4" />
                            <h2 className="mt-4">{item.title}</h2>
                            <p dangerouslySetInnerHTML={{ __html: item.text }} className="mt-3 detailed-text" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DetailedView;
