import React from "react";
import { FaQuoteLeft } from 'react-icons/fa';

const Covrage = () => {
    return (
        <section id="covrage" className="container-fluid covrage my-2">
            <div className="container">
                <div className="row gy-4" data-aos="fade-up" data-aos-delay="100">
                    <div className="col-lg-5 col-md-12">
                        <h5 className='text-start shorthead chooseus'>Understanding Our Coverage</h5>
                        <h2 className='text-start fw-bold mt-4'>Detailed Information About the Coverage Offered By UP Industrial Consultants Limited</h2>
                        <p className='text-start'>This journey of 50th years was started in the year 1974 by the Government of Uttar Pradesh. It was restructured again in 1976 by the country’s apex financial institutions, including the Industrial Development Bank of India (IDBI), the Industrial Finance Corporation of India (IFCI), and the Industrial Credit & Investment Corporation of India (ICICI).</p>
                        <a href="/about" className="btn-get-started text-white float-start">Learn More</a>
                        <video controls className="img-fluid rounded shadow mb-3 mt-2 w-100">
                            <source src="/assets/images/1682918705027049.compressed.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="col-lg-4 col-md-12 text-center">
                        {/* <img src="/assets/images/p1-1.jpg" alt="Approach Icon" className="img-fluid  rounded shadow mb-4 w-100" /> */}
                        <img src="/assets/images/7b037abb8ddaa1088c19af7b8c2f2e58.jpg" alt="Approach Icon" className="img-fluid  rounded shadow covergeimg mb-4 w-100" />

                        <div className="card text-center border-0 shadow text-box">
                            <div className="card-body text-italic p-5">
                                <FaQuoteLeft size="2em" className="mb-3 float-start text-secondary" />
                                <p className="card-text fst-italic">At Upicon, we excel in providing comprehensive solutions.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                        <div className="row gy-3">
                            <div className="col-12">
                                <div className="img-wrapper custom-img-wrapper">
                                    <img src="/assets/images/p1-1.jpg" alt="Approach Icon" className="img-fluid rounded shadow custom-img-size w-100" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="img-wrapper custom-img-wrapper">
                                    <img src="/assets/images/p12.jpg" alt="Approach Icon" className="img-fluid rounded shadow custom-img-size w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>           
            </div>
        </section>
    );
};

export default Covrage;
