import React, { useEffect, useState } from "react";
import axios from "../apiConfig"; // Import the centralized Axios instance
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const HappeningSection = () => {
  const [happenings, setHappenings] = useState([]);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedHappening, setSelectedHappening] = useState(null);
  const [viewMore, setViewMore] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  useEffect(() => {
    const fetchHappenings = async () => {
      try {
        const response = await axios.get('/happenings');
        setHappenings(response.data); // Assuming your API returns happenings directly
      } catch (error) {
        setError(error);
        console.error('Error fetching happenings data:', error);
      }
    };

    fetchHappenings();
  }, []);

  const handleShowModal = (happening) => {
    setSelectedHappening(happening);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedHappening(null);
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const truncateDescription = (description, maxLength = 100) => {
    if (description.length <= maxLength) return description;
    return description.substring(0, maxLength) + "...";
  };

  const handleViewMore = () => {
    setViewMore(!viewMore);
  };

  return (
    <section id="Happening" className="Happening my-5">
      <div className="container">
        <div className="row gy-4" data-aos="fade-up" data-aos-delay="100">
          <div className="col-lg-6 col-md-12">
            <h5 className="text-start shorthead sectionhead">Happening Now</h5>
            <h2 className="text-start fw-bold mt-5">
              UPICON Upcoming Training Programs
            </h2>
            <p className="text-start">
              This Programs aims to equip government employees with the skills
              and knowledge needed to foster UPICON has been instrumental in
              giving consultancy to big, medium, and small industries, building
              microentrepreneurial ecosystems, skills and training, banking
              inclusion, UNESCO projects, social welfare projects, women
              empowerment, safety and audit, etc.
            </p>
          </div>
          <div className="col-lg-6 col-md-12 starttedbtn">
            <div className="text-end mx-4">
            
              <Link to="/happening" className="btn  getstarted">
                                View More
                            </Link>
            </div>
          </div>
        </div>
        <div className="row mt-2 gy-4" data-aos="fade-up" data-aos-delay="100">
          {(viewMore ? happenings : happenings.slice(0, 6)).map((happening, index) => (
            <div key={index} className="col-lg-4 col-md-6 col-sm-12" data-aos="fade-up">
              <div className="card happencard rounded-top-4">
                <img
                  src={happening.image}
                  alt={happening.name}
                  className="img-fluid vertical-img rounded-top-4"
                />

<div className="card-body ">
                                
                                <div className="card-overlay mt-1">
                                    <h5 className="card-title text-start ">{happening.name}</h5>
                                    <p className="card-text text-start ">{truncateDescription(happening.description, 80)}</p>
                                    <a onClick={() => handleShowModal(happening)} className="mt-2 text-start learn-more-btn d-block">
    Learn More
</a>
                                </div>
                            </div>
              
              </div>
            </div>
          ))}
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedHappening?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={selectedHappening?.image}
            alt={selectedHappening?.name}
            className="img-fluid rounded-4 mb-3"
          />
          <p>{selectedHappening?.description}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default HappeningSection;
