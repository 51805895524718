import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "../apiConfig"; // Import the centralized Axios instance
import { Modal, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom"; // Import Link from React Router

const VirticalSection = ({ baseUrl }) => {
    const [verticals, setVerticals] = useState([]);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedVertical, setSelectedVertical] = useState(null);

    useEffect(() => {
        const fetchVirticals = async () => {
            try {
                const response = await axios.get('/virticals');
                setVerticals(response.data); 
            } catch (error) {
                setError(error);
                console.error('Error fetching virticals data:', error);
            }
        };
    
        fetchVirticals();
    }, []);
    
    useEffect(() => {
        if (verticals.length > 0) {
            const owlItems = document.querySelectorAll('.owl-carousel');
            owlItems.forEach(owl => {
                owl.addEventListener('touchstart', handleTouchStart, { passive: true });
            });

            function handleTouchStart(e) {
                // Custom logic if needed
            }

            return () => {
                owlItems.forEach(owl => {
                    owl.removeEventListener('touchstart', handleTouchStart);
                });
            };
        }
    }, [verticals]);

    const handleShowModal = (vertical) => {
        setSelectedVertical(vertical);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedVertical(null);
    };

    const truncateDescription = (description, maxLength = 100) => {
        if (description.length <= maxLength) return description;
        return description.substring(0, maxLength) + "...";
    };

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <section id="virtical" className="virtical my-2">
            <div className="container mt-4">
                <div className="row gy-4" data-aos="fade-up" data-aos-delay="100">
                    <div className="col-lg-6 col-md-12 col-sm-12 ">
                        <h5 className='text-start shorthead sectionhead'>Our Verticals</h5>
                        <h2 className='text-start fw-bold mt-5'>UPICON Offers Consultancy And Leads Social Projects</h2>
                        <p className='text-start'>UPICON has been instrumental in giving consultancy to big, medium, and small industries, building microentrepreneurial ecosystems, skills and training, banking inclusion, UNESCO projects, social welfare projects, women empowerment, safety and audit, etc.</p>
                    </div>
                    <div className="col-lg-6 col-md-12 starttedbtn">
                        <div className="text-end mx-4">
                            <Link to="/vertical" className="btn getstarted">
                                View More
                            </Link>
                        </div>
                    </div>
                </div>
                {verticals.length > 0 ? (
                    <OwlCarousel
                        className="owl-theme"
                        loop
                        margin={10}
                        autoplay={true}
                        nav={true}
                        dots={false}
                        responsive={{
                            0: {
                                items: 1,
                            },
                            600: {
                                items: 1,
                            },
                            1000: {
                                items: 3,
                            },
                            1500: {
                                items: 4,
                            },
                        }}
                    >
                        {verticals.map((vertical, index) => (
                            <div key={index} className="card vertical-card rounded-top-4 border shadow mt-5 border-0">
                            <img
                                src={vertical.image}
                                alt={vertical.name}
                                className="img-fluid vertical-img rounded-top-4"
                            />
                                <div className="card-body">
                                    <h5 className="card-title text-start">{vertical.name}</h5>
                                    <p className="card-text text-start">{truncateDescription(vertical.description, 50)}</p>
                                    <a onClick={() => handleShowModal(vertical)} className="mt-2 text-start learn-more-btn d-block">
                                        Learn More
                                    </a> 
                                </div>
                            </div>
                        ))}
                    </OwlCarousel>
                ) : (
                    <div>Loading...</div>
                )}
            </div>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedVertical?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={selectedVertical?.image} alt={selectedVertical?.name} className="img-fluid rounded-4 mb-3" />
                    <p>{selectedVertical?.description}</p>
                </Modal.Body>
            </Modal>
        </section>
    );
};

export default VirticalSection;
