// apiConfig.js

import axios from 'axios';

const instance = axios.create({
  
  baseURL: 'https://new.upicon.in/api',
  // baseURL: 'http://127.0.0.1:8000/api',
});

// Add a response interceptor to modify response data
instance.interceptors.response.use(
  response => {
    // Modify image URLs here before returning response data
    if (response.data && Array.isArray(response.data)) {
      response.data = response.data.map(item => ({
        ...item,
        image: `https://new.upicon.in/${item.image.replace(/\\/g, "/")}`
        // image: `http://127.0.0.1:8000/${item.image.replace(/\\/g, "/")}`
      }));
    } else if (response.data && response.data.image) {
      response.data.image = `https://new.upicon.in/${response.data.image.replace(/\\/g, "/")}`;
      // response.data.image = `http://127.0.0.1:8000/${response.data.image.replace(/\\/g, "/")}`;
    }
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);

export default instance;
