import React from "react";
import { AiOutlineFundProjectionScreen, AiOutlineTool, AiOutlineLike, AiOutlineSafetyCertificate } from 'react-icons/ai';

const Oursection = () => {
    return (
        <div id="oresection" className="oresection container-fluid  my-2">
            <div className="container">
                <div className="row gy-4" data-aos="fade-up" data-aos-delay="100">
                    <div className="col-lg-6 col-md-12">
                        <h5 className='text-start shorthead sectionhead'>Why Choose Us</h5>
                        <h1 className='text-start fw-bold mt-4'>Reason Why We Are The Best Consultants</h1>
                        <p className='text-start'>At Upicon, we excel in providing comprehensive consultancy services tailored to industries of all sizes. Our dedication to fostering a microentrepreneurial ecosystem, enhancing skills and training, and promoting banking inclusion sets us apart.</p>
                    </div>
                </div>
                <div className="row gy-4" data-aos="fade-up" data-aos-delay="100">
                    {[
                        { icon: <AiOutlineFundProjectionScreen size={40} />, title: "OUR APPROACH", text: "Delivering meaningful insights by deep diving into issues, operations, and the public sector." },
                        { icon: <AiOutlineTool size={40} />, title: "WHAT WE DO", text: "Our aim is to strive for holistic development by reimagining the ecosystem to unlock new value." },
                        { icon: <AiOutlineLike size={40} />, title: "Good Reputation", text: "Our mission contributes towards sustainable growth and creating a holistic ecosystem." },
                        { icon: <AiOutlineSafetyCertificate size={40} />, title: "OUR MISSION", text: "Our aim is to strive for holistic development by reimagining the ecosystem to unlock new value." }
                    ].map((item, index) => (
                        <div className="col-lg-3 col-md-6 col-sm-12 ouresection" key={index}>
                            <div className="card approach shadow">
                                <div className="card-body text-center">
                                    <div className="icon">
                                        {item.icon}
                                    </div>
                                    <h2>{item.title}</h2>
                                    <p>{item.text}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Oursection;
