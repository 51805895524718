import React, { useState, useEffect, useRef } from 'react';
import axios from '../apiConfig';
import '../assets/css/VisitorCounter.css';
import { useSpring, animated } from '@react-spring/web';

const WebsiteVisitorCounter = () => {
  const [visitCount, setVisitCount] = useState(0);
  const hasUpdated = useRef(false);

  useEffect(() => {
    if (!hasUpdated.current) {
      const fetchAndUpdateVisitCount = async () => {
        try {
          const response = await axios.post('/visit-count');
          setVisitCount(response.data.visit_count);
        } catch (error) {
          console.error('Error fetching or updating visit count:', error);
        }
      };

      fetchAndUpdateVisitCount();
      hasUpdated.current = true;
    }
  }, []);

  const resetCounter = async () => {
    try {
      const response = await axios.post('/visit-count/reset');
      setVisitCount(response.data.visit_count);
    } catch (error) {
      console.error('Error resetting visit count:', error);
    }
  };

  // Convert visitCount to padded number and split into digits
  const paddedNumber = visitCount.toString().padStart(5, '0').split('');

  // Animation setup for each digit
  const props = useSpring({
    number: visitCount,
    from: { number: 0 },
    config: { duration: 1000 },
    reset: true,
  });

  return (
    <div className='visitor-counter-container'>
      <div className='visitor-counter'>
        <p className='counter-label'>Visitors</p>
        <div className='counter-numbers'>
          {paddedNumber.map((digit, index) => (
            <div className='digit-block-container' key={index}>
              <animated.div
                className='digit-block'
                style={{
                  transform: props.number.to(n => `translateY(${Math.floor(n / Math.pow(10, 4 - index)) % 10 === parseInt(digit) ? 0 : 100}%)`),
                }}
              >
                {digit}
              </animated.div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WebsiteVisitorCounter;
