import React from 'react';
import Client from './Client'
const About = () => {
  return (
    <section id="aboutus">
      <div className="container-fluid about">
        <div className="container my-5">
          <h1 className='p-5 text-white mt-5'>We Have The Power To Impact Our Future</h1>
        </div>
      </div>
      <div className="container mt-5 px-3">
        <div className="row">
        <div className="col-lg-8 col-md-12">
          <h5 className='text-start sectionhead'>About Us</h5>
          <h2 className='text-start fw-bold mt-3'>
            We Have The Power To Impact Our Future, and We’re Doing Something About It
          </h2>
        </div>
        </div>
        <div className="row mt-3">
          <div className="col-lg-6">
            <p className="section-content text-start">
              This journey of 50 years started in 1974 by the Government of Uttar Pradesh. It was restructured again in 1976 by the country’s apex financial institutions, including the Industrial Development Bank of India (IDBI), the Industrial Finance Corporation of India (IFCI), and the Industrial Credit & Investment Corporation of India (ICICI). This 50-year journey has witnessed many dented years, but its ascent began in 2012 when seasoned professionals joined, and since then, there has been no looking back.
            </p>
            <p className="section-content text-start">
              A major brand transformation occurred when “UPICO” changed to “UPICON,” and the logo featured the image of Eklavya. This gave UPICON a new edge with the motto – “Not where you start but where you reach,” emphasizing determination and dedication. Since then, UPICON has been instrumental in providing consultancy to industries of all sizes, building micro-entrepreneurial ecosystems, offering skills and training, promoting banking inclusion, undertaking UNESCO projects, and supporting social welfare, women empowerment, safety, and audit initiatives.
            </p>
          </div> 
          <div className="col-lg-6">
            <video controls className="img-fluid rounded shadow mb-3 mt-2 w-100">
              <source src="/assets/images/1682918705027049.compressed.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        {/* New Section Added Here */}
        <div className="row mt-5">
          <div className="col-lg-12">
            <h3 className="text-start fw-bold sectionhead">Our Vision</h3>
            <p className="section-content text-start mt-3">
              Our vision is to continuously evolve and innovate, addressing the needs of various industries while contributing positively to the community. We strive to build a sustainable future through our diverse range of projects and services, focusing on excellence, integrity, and growth.
            </p>
            <p className="section-content text-start">
              We believe in leveraging our expertise to make a significant impact, not just in the industries we serve, but in the lives of people we reach. Join us in our mission to shape a better future for all.
            </p>
          </div>
        </div>
        <Client />
      </div>
    </section>
  );
};

export default About;
