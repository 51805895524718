import React from "react";

const Ourclient = () => {
  return (
    <div className="container-fluid p-5">
      <div className="container">
        <div className="row">
          {[
            { img: "images (1).jpg", alt: "Consultancy", title: "Consultancy", number: "01" },
            { img: "start.jpg", alt: "Startup Ecosystem", title: "Startup Ecosystem", number: "02" },
            { img: "download (1).jpg", alt: "Retail", title: "Retail", number: "03" },
            { img: "test.jpg", alt: "SmartCap Fund", title: "SmartCap Fund", number: "04" },
          ].map((client, index) => (
            <div className="col-lg-3 col-md-6 col-sm-12 mb-4" key={index}>
              <div className="card smartimg text-center position-relative p-4">
                <a href="">
                  <img
                    src={`/assets/images/${client.img}`}
                    alt={client.alt}
                    className="rounded-circle img-fluid"
                    style={{ width: "250px", height: "250px", objectFit: "cover" }}
                  />
                </a>
                <span className="number mt-5 text-highlight">{client.number}</span>
                <h2 className="mt-5 text-center h3 text-highlight">{client.title}</h2>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Ourclient;
